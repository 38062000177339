/** @type {import('tailwindcss').Config} */
import type { Config } from "tailwindcss";

export default {
  content: [
    "frontend/**/*.(js|jsx|ts|tsx)",
    "*/templates/**/*.html",
    "*/assets/**/*.(js|jsx|ts|tsx|css|html)",
    "*/components/**/*.(js|jsx|ts|tsx|css|html)",
    "*/forms/**/*.py",
    "web/models/banner.py",
    "content/blocks.py",
    "content/utils.py",
  ],
  theme: {
    extend: {
      textColor: ({ theme }) => ({
        primary: theme("colors.grey.600"),
        muted: theme("colors.grey.500"),
      }),
      borderColor: ({ theme }) => ({
        regular: theme("colors.grey.25"),
        transparent: "rgba(0,0,0,0)",
      }),
      screens: {
        "3xl": "1700px",
        "4xl": "2049px",
      },
      borderRadius: {
        sm: "0.4rem",
        regular: "0.5625rem",
        xl: "1.1875rem",
        full: "999px",
        none: "0px",
      },
      keyframes: {
        "progress-bar": {
          from: { transform: "translateX(-100%)" },
          to: { transform: "translateX(0%)" },
        },
      },
      animation: {
        "progress-bar": "progress-bar linear",
      },
    },
    container: {
      padding: "1rem",
      screens: {
        sm: "540px",
        md: "720px",
        lg: "960px",
        xl: "1140px",
      },
    },
    fontSize: {
      xxs: ["0.6875rem", "0.8125rem"],
      xs: ["0.75rem", "1rem"],
      sm: ["0.875rem", "1.0625rem"],
      base: ["1rem", "1.5rem"],
      xl: ["1.125rem", "1.375rem"],
      "2xl": ["1.375rem", "1.6875rem"],
      "3xl": ["1.75rem", "2.125rem"],
      "4xl": ["2.25rem", "2.5rem"],
    },
    colors: {
      white: "#ffffff",
      black: "#000000",
      muted: "#65696C",
      grey: {
        600: "#242424",
        500: "#65696C",
        400: "#93989B",
        300: "#AAB1B5",
        200: "#C6CDD0",
        DEFAULT: "#DBE2E5",
        100: "#DBE2E5",
        75: "#E0E8EC",
        50: "#EAF0F3",
        25: "#EFF3F5",
        15: "#F5F8FA",
      },
      pistachio: {
        600: "#14332C",
        500: "#295A4F",
        400: "#3A7A6C",
        300: "#499987",
        200: "#57B29E",
        DEFAULT: "#65CCB5",
        100: "#65CCB5",
        75: "#8CD9C8",
        50: "#B1E5D9",
        25: "#D8F2EC",
        15: "#E8F7F4",
      },
      blue: {
        600: "#0B2040",
        500: "#173460",
        400: "#294B80",
        300: "#426FB2",
        200: "#6393DB",
        DEFAULT: "#76A8F4",
        100: "#76A8F4",
        75: "#98BEF7",
        50: "#BAD3F9",
        25: "#DCE9FC",
        15: "#EBF2FE",
      },
      orange: {
        600: "#843200",
        500: "#9B4500",
        400: "#B35907",
        300: "#CC6D21",
        200: "#E58136",
        DEFAULT: "#F69044",
        100: "#F69044",
        75: "#F8AC73",
        50: "#FAC7A1",
        25: "#FCE3D0",
        15: "#FEEFE3",
      },
      lime: {
        600: "#4E4213",
        500: "#6A5B1E",
        400: "#907E32",
        300: "#C9B45E",
        200: "#E8D279",
        DEFAULT: "#FEE994",
        100: "#FEE994",
        75: "#FEEFAF",
        50: "#FEF3C9",
        25: "#FEF9E4",
        15: "#FFFCEF",
      },
      "light-purple": {
        600: "#66589C",
        500: "#7B6ABA",
        400: "#8573CA",
        300: "#9381D8",
        200: "#A18DEB",
        DEFAULT: "#B4A0FF",
        100: "#B4A0FF",
        75: "#C7B8FF",
        50: "#D9CFFF",
        25: "#ECE7FF",
        15: "#F4F1FF",
      },
      "dark-teal": {
        600: "#022123",
        500: "#03292C",
        400: "#053134",
        300: "#053A3E",
        200: "#034549",
        DEFAULT: "#004B50",
        100: "#004B50",
        75: "#40787C",
        50: "#7FA4A7",
        25: "#BFD2D3",
        15: "#D9E4E5",
      },
      mustard: {
        600: "#7A6529",
        500: "#977B2B",
        400: "#B5922F",
        300: "#CBA22A",
        200: "#E4B429",
        DEFAULT: "#FFCA30",
        100: "#FFCA30",
        75: "#FFD764",
        50: "#FFE497",
        25: "#FFF1CB",
        15: "#FFF7E0",
      },
      ruby: {
        600: "#481828",
        500: "#5C1F33",
        400: "#661B34",
        300: "#7E1C3D",
        200: "#971C45",
        DEFAULT: "#AB194A",
        100: "#AB194A",
        75: "#C05377",
        50: "#D48BA4",
        25: "#EAC5D1",
        15: "#F3DDE4",
      },
      blueberry: {
        600: "#14202C",
        500: "#1B2C3C",
        400: "#22374B",
        300: "#294259",
        200: "#264058",
        DEFAULT: "#2C4964",
        100: "#2C4964",
        75: "#61778B",
        50: "#95A3B1",
        25: "#CAD1D8",
        15: "#E0E4E8",
      },
      "dark-purple": {
        600: "#160420",
        500: "#1E062C",
        400: "#29083C",
        300: "#310947",
        200: "#3F095B",
        DEFAULT: "#460967",
        100: "#460967",
        75: "#74478D",
        50: "#A283B2",
        25: "#D0C1D9",
        15: "#E3DAE8",
      },
      raspberry: {
        600: "#5E1D2A",
        500: "#7B2538",
        400: "#8F2C41",
        300: "#A8334C",
        200: "#C03E59",
        DEFAULT: "#D94161",
        100: "#D94161",
        75: "#E37189",
        50: "#EB9FAF",
        25: "#F5CFD7",
        15: "#FAE3E8",
      },
      transparent: "rgba(0,0,0,0)",
    },
    boxShadow: {
      regular: "0px 1px 9px 6px rgba(0, 0, 0, 0.03)",
      strong: "0px 1px 6px 2px rgba(0, 0, 0, 0.1)",
      none: "0 0 #0000",
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain",
      100: "100%",
    },
  },
  plugins: [],
  prefix: "tw-",
  corePlugins: {
    preflight: false,
  },
} satisfies Config;
