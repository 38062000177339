import Cookies from "js-cookie";

import { consentToAllInGtm } from "../shared/gtm";

const COOKIE_CONSENT_NAME = "cookie-consent";
const COOKIE_CONSENT_SETTINGS: Partial<Cookies.CookieAttributes> = {
  expires: 365 * 3, // 3 years
  path: "/",
  sameSite: "lax",
};

if (process.env.COOKIE_CONSENT_DOMAIN) {
  COOKIE_CONSENT_SETTINGS.domain = process.env.COOKIE_CONSENT_DOMAIN;
}

export const ACCEPT_ALL_COOKIES_EVENT = "cookies-accept-all";

const getConsentPreferences = (): boolean => {
  return JSON.parse(Cookies.get(COOKIE_CONSENT_NAME) || "false");
};

const setConsentPreferences = (consent: boolean): void => {
  const secure = window.location.protocol === "https:";
  Cookies.set(COOKIE_CONSENT_NAME, JSON.stringify(consent), {
    ...COOKIE_CONSENT_SETTINGS,
    secure,
  });
};

const triggerAcceptAllEvent = (): void => {
  document.dispatchEvent(new Event(ACCEPT_ALL_COOKIES_EVENT));
};

export const consentToAll = (): void => {
  setConsentPreferences(true);

  consentToAllInGtm();

  triggerAcceptAllEvent();
};

export const rejectAll = (): void => {
  setConsentPreferences(false);
};

export const hasConsent = (): boolean => {
  return getConsentPreferences();
};
