export function waitForElementWithId<T extends HTMLElement = HTMLElement>(
  selector: string,
): Promise<T> {
  return new Promise<T>((resolve) => {
    if (document.getElementById(selector)) {
      resolve(document.getElementById(selector) as T);
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector) as T);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export class DomEventEmitter {
  private observer?: MutationObserver;
  private listeners: Record<string, Array<(el: HTMLElement) => unknown>> = {};
  private isListening = false;

  public on(id: string, listener: (el: HTMLElement) => unknown) {
    if (!this.listeners[id]) {
      this.listeners[id] = [];
    }

    this.listeners[id].push(listener);
  }

  private stop() {
    this.observer?.disconnect();
    this.isListening = false;
  }

  private emit(id: string, el: HTMLElement) {
    if (!this.isListening) {
      return;
    }

    if (this.listeners[id]) {
      this.listeners[id].forEach((listener) => listener(el));
    }

    // in future, we could have different strategy to not stop at the first emit
    this.stop();
  }

  public listen() {
    this.isListening = true;
    for (const id in this.listeners) {
      const el = document.getElementById(id);
      if (el) {
        this.emit(id, el);
      }
    }

    this.observer = new MutationObserver(() => {
      for (const id in this.listeners) {
        const el = document.getElementById(id);
        if (el) {
          this.emit(id, el);
        }
      }
    });

    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }
}
