import { AwsRum, AwsRumConfig } from "aws-rum-web";

import { hasConsent } from "./cookies";

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 0.05,
    guestRoleArn: process.env.RUM_GUEST_ROLE_ARN,
    identityPoolId: process.env.RUM_IDENTITY_POOL_ID,
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance", "http"],
    allowCookies: hasConsent(),
    enableXRay: true,
  };
  const APPLICATION_ID = process.env.RUM_APP_MONITOR_ID || "";
  const APPLICATION_VERSION = "1.0.0";
  const APPLICATION_REGION = "eu-west-1";
  // eslint-disable-next-line no-new
  new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
