import { type ClassValue, clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

import tailwindConfig from "../../tailwind.config";

export const twMerge = extendTailwindMerge({
  prefix: "tw-",
  override: {
    classGroups: {
      shadow: Object.keys(tailwindConfig.theme.boxShadow),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
